import { InputTypes } from '@eagle/common';
import { Person, Thing } from '@eagle/core-data-types';
import {
  CacheDataTypes,
  EntityItems, EntityVisibility, EventDialogProvider,
  EventFields,
  EventHistory,
  EventHistoryAlert,
  EventHistoryMap,
  EventLocationData,
  FilterField,
  HistoryDrawerController,
  HistorySearchProvider,
  HISTORY_MAP_CENTER,
  ListSearchProvider,
  MapStorageKeys,
  Maybe,
  MiddleSpinner,
  MobileTabSelection,
  ShareableHistoryData,
  Undefinable,
  useFetchOneCache,
  usePromise,
  useTitle
} from '@eagle/react-common';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ulid } from 'ulid';
import { EventHistoryNavigator } from './event-history-navigator';

export const EventHistoryController: FC = () => {
  const { data } = useParams();
  const initialData = useRef(data);
  const { t } = useTranslation(['common', 'track']);
  useTitle(t('common:common.labels.history'));
  const [filterOpen, setFilterOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [hoveredEventId, setHoveredEventId] = useState<Maybe<string>>(null);
  const [selectedEvent, setSelectedEvent] = useState<Maybe<EventLocationData>>(null);
  const { enqueueSnackbar } = useSnackbar();
  const debouncedErrorToastRef = useRef(debounce(() => {
    enqueueSnackbar(t('track:page.history.history-data-not-found.label'), { variant: 'error' });
  }, 200));
  const debouncedErrorToast = debouncedErrorToastRef.current;
  const thingCache = useFetchOneCache(CacheDataTypes.THING);
  const personCache = useFetchOneCache(CacheDataTypes.PERSON);

  const keyDownHandler = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'F' && event.shiftKey && event.altKey) setFilterOpen(!filterOpen);
  }, [filterOpen]);

  const fields: FilterField[] = [
    {
      definition: {
        description: null,
        format: 'raw',
        input: InputTypes.CHECK,
        label: t('track:page.history.event-category.label'),
        multiple: null,
        type: 'entity',
      },
      path: 'eventTypeId',
      values: [
        { id: EventFields.DRIVER_BEHAVIOR, display: t('common:features.driver-behavior') },
        { id: EventFields.MEDIA, display: t('track:page.history.labels.media') },
        { id: EventFields.SAFETY_INCIDENT, display: t('common:component.filter-dropdown.labels.safety-incidents') },
        { id: EventFields.TRACKING, display: t('common:component.filter-dropdown.labels.tracking') },
        { id: EventFields.OTHER, display: t('common:component.filter-dropdown.labels.other') },
      ],
    },
  ];

  const renderHistoryContent = (mobileTabSelection: MobileTabSelection): JSX.Element => (
    <HistoryDrawerController
      fields={fields}
      filterDrawerOpen={filterOpen}
      handleDrawerToggle={setIsDrawerOpen}
      handleFilterOpen={setFilterOpen}
      hoveredEventId={hoveredEventId}
      isDrawerOpen={isDrawerOpen}
      isTransitioning={isTransitioning}
      mobileTabSelection={mobileTabSelection}
      selectedEvent={selectedEvent}
      setHoveredEventId={setHoveredEventId}
      setSelectedEvent={setSelectedEvent}
      storageKey="history-page"
    />
  );

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  const decodeData = useCallback(async (encodedStr: string) => {
    const decodedData = JSON.parse(window.atob(encodedStr)) as ShareableHistoryData;
    if (!decodedData?.entities?.length) return ;

    const isValidEntities = await Promise.all(decodedData.entities.map(async (entity) => {
      switch (entity.type) {
        case CacheDataTypes.THING:{
          const thing = await thingCache.one<Thing>(entity._id);
          if (!thing || thing.deleted) return false;
          break;
        }
        case CacheDataTypes.PERSON:{
          const person = await personCache.one<Person>(entity._id);
          if (!person || person.deleted) return false;
          break;
        }
        default:
          return false;
      }
      return true;
    }));

    if (isValidEntities.some((isValid) => !isValid)) return;
    return decodedData;
  }, [personCache, thingCache]);

  const [decodedData, , stateDecodedData] = usePromise<Undefinable<ShareableHistoryData>>(
    async () => {
      if (!initialData.current) return;
      try {
        const decodedData = await decodeData(initialData.current);
        if (!decodedData) {
          if (debouncedErrorToast) debouncedErrorToast();
          return;
        }
        return decodedData;
      } catch (error) {
        if (debouncedErrorToast) debouncedErrorToast();
        return;
      }
    }, [debouncedErrorToast, decodeData]
  );

  const defaultEntityItems = useMemo<Undefinable<EntityItems[]>>(
    () => {
      if (!decodedData) return;
      let colorIndex = -1;
      return decodedData.entities.map((item) => {
        const entityType = CacheDataTypes.THING_TYPE === `${item.type}-type` ? CacheDataTypes.THING_TYPE : CacheDataTypes.PERSON_TYPE;
        const entityItems: EntityItems = {
          id: item._id,
          entityType,
          entity: CacheDataTypes.THING === item.type ? CacheDataTypes.THING : CacheDataTypes.PERSON,
          entityTypeKey: CacheDataTypes.THING === item.type ? 'thingTypeId' : 'personTypeId',
          journeyList: item.journeys.map((journey) => {
            colorIndex++;
            return ({
              colorIndex,
              dateRange: {
                startTime: DateTime.fromISO(journey.start).toJSDate(),
                endTime: DateTime.fromISO(journey.finish).toJSDate(),
              },
              entityId: item._id,
              entityType,
              focused: true,
              id: ulid(),

            });}),
        };
        return entityItems;
      });
    },
    [decodedData],
  );

  const defaultVisibilities = useMemo(() => {
    return defaultEntityItems?.map((defaultEntityItem) => {
      const defaultVisibilityItem: EntityVisibility = {
        entityId: defaultEntityItem.id,
        isVisible: true,
        journeyVisibility: defaultEntityItem.journeyList.map((journey) => ({
          isVisible: true,
          journeyId: journey.id,
        })),
      };
      return defaultVisibilityItem;
    });
  }, [defaultEntityItems]);

  const defaultSelectedEventId = useMemo(() => {
    if (!decodedData) return;
    return decodedData.eventId;
  }, [decodedData]);

  if (stateDecodedData === 'pending') return <MiddleSpinner />;

  return (
    <HistorySearchProvider defaultEntityItems={defaultEntityItems} defaultSelectedEventId={defaultSelectedEventId} defaultVisibilities={defaultVisibilities}>
      <EventDialogProvider setSelectedEvent={setSelectedEvent}>
        <ListSearchProvider dataKey="event-history">
          <EventHistoryNavigator />
          <EventHistory
            isDrawerOpen={isDrawerOpen}
            renderBottomDrawerContent={() => <></>}
            renderHistoryContent={renderHistoryContent}
            renderTopDrawerContent={() => (
              <EventHistoryMap
                center={HISTORY_MAP_CENTER}
                hoveredEventId={hoveredEventId}
                selectedEvent={selectedEvent}
                setHoveredEventId={setHoveredEventId}
                setSelectedEvent={setSelectedEvent}
                storageKey={MapStorageKeys.HISTORY_MAP_SAVED_POSITION}
              >
                <EventHistoryAlert
                  message={<Trans components={[<i key="key-1" />]} i18nKey="track:page.history.no-history-message.labels" />}
                  sx={{ p: 2, position: 'absolute', width: '100%', zIndex: 1300 }}
                  title={t('track:page.history.no-history.labels')}
                />
              </EventHistoryMap>
            )}
            setIsTransitioning={setIsTransitioning}
          />
        </ListSearchProvider>
      </EventDialogProvider>
    </HistorySearchProvider>
  );
};
